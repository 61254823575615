<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" title="查看详情">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="150px">
					<el-form-item label="不通过的原因" v-if="ruleForm.status==2">
						<span class="xx"></span>
						<el-input type="textarea" disabled clearable v-model="ruleForm.failure_reason"
							placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="公司名称">
						<span class="xx"></span>
						<el-input disabled clearable v-model="company_name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="是否是农民工会会员">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.is_agricultural_worker_member" placeholder="">
							<el-option label="是" :value="1"></el-option>
							<el-option label="否" :value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.gender" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.mobile_number" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="出生日期">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.birth_date" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="身份证号">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.id_number" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="民族">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.ethnicity" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="工会身份">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.union_roles" placeholder="">
							<el-option label="工会主席、副主席" :value="0"></el-option>
							<el-option label="女职工委员会主任、副主任" :value="1"></el-option>
							<el-option label="女职工委员会委员" :value="2"></el-option>
							<el-option label="经费审查委员主任、副主任" :value="3"></el-option>
							<el-option label="经费审查委员会委员" :value="4"></el-option>
							<el-option label="委员会委员" :value="5"></el-option>
						</el-select>
						<div class="edits" @click="binedits">编辑</div>
					</el-form-item>
					<el-form-item label="就业状况">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.employment_status" placeholder="">
							<el-option label="在岗" :value="1"></el-option>
							<el-option label="待(下)岗" :value="2"></el-option>
							<el-option label="失业" :value="3"></el-option>
							<el-option label="退休" :value="4"></el-option>
							<el-option label="退职" :value="5"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="学历">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.education" placeholder="">
							<el-option label="博士" :value="1"></el-option>
							<el-option label="硕士" :value="2"></el-option>
							<el-option label="本科" :value="3"></el-option>
							<el-option label="大专" :value="4"></el-option>
							<el-option label="中专" :value="5"></el-option>
							<el-option label="高中" :value="6"></el-option>
							<el-option label="初中" :value="7"></el-option>
							<el-option label="小学及以下" :value="8"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="技术等级">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.tech_level" placeholder="">
							<el-option label="无" :value="0"></el-option>
							<el-option label="职业资格一级（高级技师)" :value="1"></el-option>
							<el-option label="职业资格二级(技师)" :value="2"></el-option>
							<el-option label="职业资格三级(高级)" :value="3"></el-option>
							<el-option label="职业资格四级（中级)" :value="4"></el-option>
							<el-option label="职业资格五级（初级)" :value="5"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="工作单位">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.workplace" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="籍贯">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.native" placeholder=""></el-input>
					</el-form-item>

					<el-form-item label="户籍类型">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.household_type" placeholder="">
							<el-option label="无" :value="0"></el-option>
							<el-option label="农业户口" :value="1"></el-option>
							<el-option label="非农业户口" :value="2"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="户籍所在地">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.household_location" placeholder=""></el-input>
					</el-form-item>

					<el-form-item label="户籍变化类型">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.membership_change_type" placeholder="">
							<el-option label="入会" :value="0"></el-option>
							<el-option label="转入" :value="1"></el-option>
							<el-option label="保留" :value="2"></el-option>
							<el-option label="转出" :value="3"></el-option>
							<el-option label="除名" :value="4"></el-option>
							<el-option label="退会" :value="5"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="户籍变化日期">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.membership_change_date"
							placeholder=""></el-input>
					</el-form-item>

					<el-form-item label="户籍变化原因">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.membership_change_reason" placeholder="">
							<el-option label="劳动(工作)关系发生变化" :value="1"></el-option>
							<el-option label="在本单位下岗后待岗" :value="2"></el-option>
							<el-option label="失业" :value="3"></el-option>
							<el-option label="所在企业破产" :value="4"></el-option>
							<el-option label="个人要求退会" :value="5"></el-option>
							<el-option label="因犯罪被开除会籍" :value="6"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="政治面貌">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.political_affiliation" placeholder="">
							<el-option label="中共党员" :value="1"></el-option>
							<el-option label="中共预备党员" :value="2"></el-option>
							<el-option label="共青团员" :value="3"></el-option>
							<el-option label="民革党员" :value="4"></el-option>
							<el-option label="民盟盟员" :value="5"></el-option>
							<el-option label="民建会员" :value="6"></el-option>
							<el-option label="民进会员" :value="7"></el-option>
							<el-option label="农工党党员" :value="8"></el-option>
							<el-option label="致公党党员" :value="9"></el-option>
							<el-option label="九三学社社员" :value="10"></el-option>
							<el-option label="台盟盟员" :value="11"></el-option>
							<el-option label="无党派人士" :value="12"></el-option>
							<el-option label="群众" :value="13"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 编辑 -->
		<edits ref="edits" @SubmitForm="SubmitForm" />
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	import edits from '@/components/useredits.vue'
	export default {
		components: {
			edits
		},
		data() {
			return {
				outerVisible: false,
				ruleForm: {},
				id: '',
				company_name: ''
			}
		},
		methods: {
			// 编辑
			binedits() {
				this.$refs.edits.show(this.id)
			},
			SubmitForm() {
				this.$emit("SubmitForm")
				this.getinfo()
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.TradeRead, {
						id: this.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.company_name = res.data.trade.company_name
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.id = id
				this.getinfo()
			}
		}
	}
</script>

<style scoped lang="scss">
	video {
		width: 300px;
		height: 250px;
	}

	.edits {
		text-align: center;
		font-size: 14px;
		width: 80px;
		cursor: pointer;
		color: #488bff;
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>
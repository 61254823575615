import request from './request'

const api = {
	// 获取列表
	lists: '/gh/Trade/getPageList',
	// 删除
	dele: '/gh/Trade/delete',
	// 开会时间详情
	getTime: '/gh/Meet/read',
	// 开会时间编辑
	editsTime: '/gh/Meet/update',
	// 工会详情
	info: '/gh/Trade/read',
	// 获取委员会员
	getzhu: '/gh/Member/getMember',
	// 大会选举详情
	getElect: '/gh/Elect/getElect',
	// 工会人员分页列表
	getPageList: '/gh/TradeViper/getPageList',
	// 工会人员详情
	TradeRead: '/gh/TradeViper/read',
	// 导出
	export: '/gh/TradeViper/export',
	// 工会人员删除
	delete: '/gh/TradeViper/delete',
	// 工会审核
	review: '/gh/Trade/review',
	// 工会人员编辑
	TradeViperupdate: '/gh/TradeViper/update',
	// 工会导出
	ghexport: '/gh/Trade/export',
	// 选举委员导出
	ElectViperExport: '/gh/ElectViper/export',
	// 工会委员会员导出
	MemberExport: '/gh/Member/export',
	// 修改操作员
	updateOperator: '/gh/Trade/updateOperator',
	// 工会人员列表
	TradeVipergetList: '/gh/TradeViper/getList',
	// 后台创建公会
	create: '/gh/Trade/create',
}

export {
	request,
	api
}

<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" title="编辑工会身份">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="工会身份">
						<span class="xx">*</span>
						<el-select clearable @change="change" v-model="ruleForm.union_roles" placeholder="请选择工会身份">
							<el-option label="工会主席、副主席" value="0"></el-option>
							<el-option label="女职工委员会主任、副主任" value="1"></el-option>
							<el-option label="女职工委员会委员" value="2"></el-option>
							<el-option label="经费审查委员主任、副主任" value="3"></el-option>
							<el-option label="经费审查委员会委员" value="4"></el-option>
							<el-option label="委员会委员" value="5"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {
					id: '',
					union_roles: ''
				}
			}
		},
		methods: {
			// 确定
			submitForm() {
				if (this.ruleForm.union_roles=='') {
					ElMessage({
						message: '请选择工会身份',
						type: 'warning',
					})
				} else {
					this.$http.post(api.TradeViperupdate, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '编辑成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}
			},
			// 获取信息
			getinfo(id) {
				this.$http.post(api.TradeRead, {
						id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm.union_roles = res.data.union_roles.toString()
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.ruleForm.id = id
				this.getinfo(id)
			}
		}
	}
</script>

<style scoped lang="scss">
	video {
		width: 300px;
		height: 250px;
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>